declare global {
  interface Window {
    gtag: (option: string, gaTrackingId: string, options: Record<string, unknown>) => void;
  }
}

/**
 * @example
 * https://developers.google.com/analytics/devguides/collection/gtagjs/pages
 */
export const pageview = (url: string) => {
  if (!window.gtag) {
    console.warn(
      'window.gtag is not defined. This could mean your google analytics script has not loaded on the page yet.',
    );
    return;
  }
  window.gtag('event', 'page_view', {
    page_title: document.title,
    page_location: url,
  });
};

/**
 * @example
 * https://developers.google.com/analytics/devguides/collection/gtagjs/events
 */
export const event = (eventName: string, payload: Record<string, string> = {}) => {
  if (!window.gtag) {
    console.warn(
      'window.gtag is not defined. This could mean your google analytics script has not loaded on the page yet.',
    );
    return;
  }
  window.gtag('event', eventName, payload);
};
